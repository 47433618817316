<template>
  <div class="container">
    <div class="main">
      <yanzheng />
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>
      <div class="player listmain type-lhc twlh game-twlh game-lhc-lm">
        <div class="data">
          <h3>两面</h3>
          <ul v-for="item in rowsData" :key="item.title">
            <li :class="ele.selected ? 'selected' : ''" v-for="ele in item.arr" :key="ele.ResultID" @click="setCurrentSelected(ele)">
              <ol class="td_name">
                {{
                  ele.label
                }}
              </ol>
              <ol class="td_rate">
                {{
                 ele.Odds
                }}
              </ol>
              <ol class="td_cash">
                <input type="text" @click.stop="showOrHideCheck($event, ele)" v-model="ele.money" />
                <div class="quick-check" v-if="ele.b">
                  <ul>
                    <li v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(ele, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click="closeCheck(ele)">关闭</li>
                  </ul>
                </div>
              </ol>
            </li>
          </ul>
        </div>
      </div>
      <yushe />
    </div>

    <div class="other">
      <div class="zezhao" v-if="zezhao||weihu"></div>
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long" v-if="changelongActive=='1'">
          <!-- <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div> -->
          <ul class="longlist" v-if="kaiActive=='1'">
            <li v-for="item in rclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="red">{{item.num}}期</b>
            </li>
          </ul>
          <ul class="longlist" v-if="kaiActive=='2'">
            <li v-for="item in lclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="blue">{{item.num}}期</b>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import yanzheng from "../yanzheng.vue";
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import mixins from "../mixins/mainMixins";
export default {
  name: "",
  props: [""],
  data() {
    return {
      groupnames:'lm',
      rowsData: [{ title: "两面", arr: [] }],
      changelongActive: "1",
      kaiActive: "1",
      labelArr: [
        "特单",
        "特双",
        "特大",
        "特小",
        "特合单",
        "特合双",
        "特合大",
        "特合小",
        "特家禽",
        "特野兽",
        "特小尾",
        "特大尾",
        // "特尾单",
        // "特尾双",
        "总和单",
        "总和双",
        "总和大",
        "总和小",
        "特大单",
        "特小单",
        "特大双",
        "特小双",
      ],
    };
  },
  components: {
    topTimer,
    yushe,
    yanzheng,
  },
  mixins: [mixins],
  watch: {
    // 判断是否可以点击确定提交按钮
    rowsData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
            item.arr.forEach((ele) => {
              if (ele.money) {
                let obj = {
                  label: ele.label,
                  Odds: ele.Odds,
                  title: item.title,
                  id: ele.ResultID,
                  money: Number(ele.money),
                };
                arr.push(obj);
              }
            });
          });

          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
    // j监听数据
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        let arr1 = this.ddFilterData1(
          resAArr,
          [
            465, 466, 467, 468, 471, 472, 469, 470, 3968, 3969, 474, 473, 575,
            576, 573, 574, 3970, 3972, 3971, 3973,
          ],
          this.labelArr
        );

        this.rowsData[0].arr = arr1;

        this.$forceUpdate();
      },
    },
  },
  created() {},

  methods: {
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
  },
};
</script>
<style scoped>
.listmain > .data > ul > li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  min-height: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto;
  cursor: pointer;
}
</style>
